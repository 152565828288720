import ApiService from "./api.service";
let main = process.env.VUE_APP_ROOT_API2;
export default {
  loginRequestToOneId({ code, state }) {
    return ApiService.get(`${main}/redirectUri?code=${code}&state=${state}`);
  },
  logOut() {
    return ApiService.get(`${main}/logOut`);
  },
  getDataUser() {
    return ApiService.get(`${main}/currentLawyerCandidate`);
  },
  getDataLawyer() {
    return ApiService.post(`getInfo/findNewLawyerByPnfl`, {});
  },
  sendDocs(lawyerId, data) {
    let formdata = new FormData();
    for (const id in data) {
      if (id == "licenceTypeId" || id == "regionId") {
        formdata.append(id, data[id]);
      } else {
        formdata.append(`multipartFile`, data[id]);
        formdata.append(`docTypeIds`, id);
      }
    }
    return ApiService.formData(
      `candidateDocument/createCandidateDoc/${lawyerId}`,
      formdata
    );
  },
  getKeyForLoginSign(inn, pnfl) {
    return ApiService.get(`/keyForLoginSign?TIN=${inn}&PNFL=${pnfl}`);
  },
  getChallenge(isLogin) {
    return ApiService.get(`/${isLogin ? "frontend" : "getInfo"}/challange`);
  },
  mobileIdByPin(templateKey) {
    return ApiService.get(`/mobileId/byPin?templateKey=${templateKey}`);
  },
  mobileIdVerify(code) {
    return ApiService.get(`/mobileId/verify?code=${code}`);
  },
    getClientInfo() {
        return ApiService.get(`clients/clientsInfo`);
    },
    getClientRequestList(search,pagination){
      return ApiService.post(`clients/listRequest?isClient=${search}`,pagination)
    }
};
