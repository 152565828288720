export const locale = {
    // actions
    name: {
        lt: "Nomi (lotin)",
        ru: "Название (русский)",
        uz: "Номи (кирилл)",
        en: "Name (english)",
    },
    lang: {
        russian: "Русский",
        uzbekLatin: "O‘zbek (Lotin)",
        uzbekKrill: "Ўзбек (кирилл)",
        english: "English",
    },
    /////////\\\\\\STATUS\\\\\\\\\\\\///////
    status: {
        DRAFT: "Draft",
        PRINTED: "Printed",
        status: "Status",
        active: "active",
        inactive: "inactive",
        notFound: "undefined",
        SEND: "Sent",
        SEEN: "Viewed",
        CANCELLED: "Cancelled",
        ACCEPTED: "Accepted",
        MEETING: "Meeting",
        MEETING_PROCESS: "Meeting in progress",
        PASSED: "Passed",
        FAILED: "Failed",
        ABSENT: "Absent",
        cancelled: "Cancelled",
        send: "Sent",
        sendToSign: "Sent to sign",
        signed: "Signed",
        accepted: "Accepted",
        signedDeportament: "Signed by department",
        successInput: "Successfully entered",
        COMPLETED: "Completed",
        CLOSED: "Closed",
        SEND_CONVICTION: "Sudlananlikka tekshirilmoqda",
        CONFIRM: "Shartnoma tuzilgan",
    },

    winning: {
        card1: {
            title: "Sending appeals",
            text: "For people with disabilities",
        },
        card2: {
            title: "Lawyers Statistics",
            text: "All lawyer statistics and useful information",
        },
        card3: {
            title: "Lawyers Rating",
            text: "Special rating of lawyers and working with them",
        },
    },
    views: "Views",
    categorieslist: {
        item1: {
            title: "Crime - administrative",
            text: "About amendments and additions to the Criminal, Criminal-Procedural and Administrative Liability Codes of the Republic of Uzbekistan",
        },
        item2: {
            title: "Citizenship - Economic",
            text: "About amendments and additions to the Criminal, Criminal-Procedural and Administrative Liability Codes of the Republic of Uzbekistan",
        },
    },
    contract: {
        signing: "Signing of the contract",
        numberDate: "Contract number and date",
    },
    dialog: {
        yes: "Yes",
        no: "No",
    },
    editProfile: {
        title: "Edit profile",
        name: "Name",
        surname: "Surname",
        patronymic: "Patronymic",
        phone: "Phone number",
        email: "Email",
        picture: "Picture",
        address: "Address",
    },
    orders: {
        all: "Orders",
        orderInfo: "Order information",
        electronOrder: "In electronic form orders",
        documenttalOrder: "In paper form orders",
        groundsForGiven: "Grounds for given",
        viewPeperForm: "View paper form",
        sureCancelOrder: "Are you sure you want to cancel the order?",
        createEOrder: "Create electronic order",
        list: "List",
        countsAll: "All orders count",
        sends: "Sent",
        cancelleds: "Cancelled",
        snetLate: "Sent late",
        electron: "Electronic",
        paper: "Paper",
        youNorCreateYet: "You have not created any orders yet",
        viewPpaperForm: "View paper form",
        viewOrder: "View order",
        addToReportForm: "Add to report form",
        sendOrder: "Send order",
        createElectronOrder: "Create electronic order",
        createPaperOrder: "Create paper order",
        whatOrderWho: "ORDER to conduct the case by a lawyer",
        selectedDocumentNumber: "Selected document number",
        selectedDocumentDate:
            "The date of issuance of the document that is the basis for issuing the order",
        personSelectTitle: "Information about the trusting (protected) person",
        orderDate: "Order date",
        inputFileOrder: "Upload the paper warrant file",
        inputPaperFormOrder: "Input paper form order",
        legalAidWorkType: "Type of case for which legal assistance is provided",
        orderType: "Order type",
        saveToDrafts: "Save to drafts",
        sureDelete: "Are you sure you want to delete?",
        personalOrders: "Personal orders",
        contragentOrders: "Contragent orders",
        createdDate: "Order creation date",
        createFeature: "Create order feature",
        cancelCreatePermission: "Cancel permi to create order",
        canCreatePermission: "Allow order creation",
        limitedByContragent:
            "Order yaratish tuzilma tomonidan cheklab qo'yilgan",
        expertise: "Expertise"
    },
    sidebar: {
        controlPanel: "Control panel",
        myWorks: "My works",
        requests: "Requests",
        contracts: "Contracts",
        orders: "Orders",
        statisticInfo: "Statistics",
        profile: "Profile",
        contragentOrLawyerLetters: "Correspondence between lawyers",
        letterDocuments: "Letters and documents",
        control: "Control",
        userGuide:"User guide",
        documentsChamberOfAdvocates:"Documents of the Chamber of Advocates",
        legalServiceInfo:"Legal Service"



    },
    aLetterCameFrom: "A letter from {fullName} has come to you",
    fromWho: "From whom",
    responseLetter: "Response letter",
    incomingLetter: "Incoming letter",
    toWhichLetter: "To which letter it was sent",
    letterType: "Letter type",
    sendResponseToLetter: "Send response letter",
    sendLetter: "Send letter",
    CheckServiceDoc: "Check document",
    success: {
        editeProfile: "Profile successfully edited",
        delete: "Successfully deleted",
        save: "Successfully saved",
        cancel: "Successfully cancelled",
        completed: "Successfully completed",
    },
    lawyer: {
        FISH: "Lawyer full name",
    },
    times: {
        month: "Month",
        day: "Day",
        week: "Week",
    },
    months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
    },
    request: {
        list: "List of requests",
        create: "Create request",
        allCount: "All requests count",
        inProgress: "In progress",
        accepteds: "Accepted",
        cancels: "Cancelled",
        all: "All requests",
        progresses: "In progress",
        createDate: "Request creation date",
        number: "Request number",
        type: "Request type",
        status: "Request status",
        view: "View request",
        requestForSendLegalAid: "Request for legal aid",
        replaceCertificate: "Request for replacement of a lawyer's certificate",
        replaceLicense: "Request for replacement of a lawyer's license",
        statusStoping: "Application for suspension of lawyer status",
        forFreeHelp: "Request for free legal aid",
        imgForLawyerCertificate: "Image of lawyer's certificate",
        imageReason:
            "If you do not upload your 3x4 color photo, your photo from the database of the State Personalization Center will be placed on the certificate.",
    },
    titles: {
        personalInfo: "Personal information",
        lawyerLicenseInfos: "Lawyer license information",
        lawyerCertificateInfos: "Lawyer certificate information",
        lawyerContragentInfos: "Lawyer contragent information",
        litcenseType: "License type",
        licensingAuthority: "Licensing authority",
        certificatingAuthority: "Certificating authority",
        workExperienceAndAchievementsOfTheLawyer:
            "Work experience and achievements of the lawyer",
        mainLinesOfActivity: "Main lines of activity",
    },
    lawyerStatuses: {
        licenceStop: "Litcense suspended",
        licenceActive: "Litcense active",
        licenceEnd: "Litcense expired",
        statusStop: "Legal status suspended",
        status: "Lawyer status",
        licenseStatus: "License status",
    },
    user: {
        user: "User",
        fullName: "Full name",
        jshshr: "Personal identification number (PINI)",
        address: "Address",
        personalInfo: "Personal information",
        appeals: "Appeals",
        firstName: "First name",
        lastName: "Last name",
        middleName: "Parent name",
    },
    appeals: {
        appeal: "Appeal",
        appeals: "Appeals",
        myAppeals: "My appeals",
        allAppeals: "All appeals",
        acceptedAppeals: "Accepted appeals",
        create: "Create appeal",
        region: "Region",
        type: "Type",
        commentWork: "Brief description of the work",
        commentWork2: "Clear description of the work",
        dontPermission: "You do not have access to create a case.",
        youNotInvalide:
            "This section was created to assist law firms and lawyers in providing voluntary free legal assistance (pro bono) to persons with disabilities. No data on your involvement with persons with disabilities was found in your PINFL.",
        byAppealsInfos: "By appeals information",
        empty: "Appeals list is empty",
        regionAndPhone: "Region and phone number",
        appealSender: "Appeal sender",
        accept: "Accept appeal",
        sureAccept:
            "Do you really want to provide free legal assistance on this appeal?",
        accepted: "Accepted appeal",
        createEAppeals: "Create e-appeal",
        contentWork: "Content of the work",
        send: "Send appeal",
        fieldOf: "Field of appeal",
        dateSend: "Date of sending",
        addFile: "Add file",
        sureConfirm:
            "I got acquainted with the rules of sending an application",
        view: "View appeal",
        viewUploadFile: "View uploaded file",
        complation: "Complation of the appeal",
        attechedLawyer: "Attached lawyer",
        aboutCompleted: "About completed",
        certificateSendAddress: "Address for sending the lawyer's certificate",
        labelList:
            "Applications of persons with disabilities for voluntary free legal aid (pro bono).",
    },
    readMored: "Read more...",
    certificate: {
        changeNeason: "Reason for obtaining/renewing a certificate",
    },
    licenseFileShow: "View license file",
    requestAcceptedConnecting: "Request accepted, contact the applicant",
    uploadFile: "Upload file",
    id: "ID",
    cityTaoun: "City/District",
    applicant: "Applicant",
    userRequests: "User requests",
    lawyersConrtagent: "Lawyers in the contragent",
    legislativeNews: "Legislative news",
    yes: "Yes",
    documentType: "Document type",
    drafts: "Drafts",
    electronSigning: "Electronic signing",
    fieldMandatory: "This field is required",
    listEmpty: "List is empty",
    protectedPerson: "Protected person",
    requireTimeRange: "The time range is required",
    profile: "Profile",
    isProvided: "Legal aid is provided",
    systemRunningTestMode: "The system is running in test mode",
    awaitingYourReply: "Awaiting your reply",
    specialty: "Specialty",
    seriaAndNumber: "Series and number",
    cancelingRequest: "Canceling request",
    acceptingRequest: "Accepting request",
    acceptedRequest: "Accepted request",
    canceledRequest: "Canceled request",
    closedRequest: "Closed request",
    sentRepot: "Sent report",
    reportNotClose: "Report not closed",
    paymnetApproved: "Payment approved",
    sendFiltesNotFull: "Send filters not full",
    viewRequest: "View request",
    sendRepot: "Send report",
    successReport: "The report was successfully sent",
    reportFile: "Report file",
    shortAboutWork: "Short description of the work",
    notifications: "Notifications",
    certificateRequest: "Submit an application for a certificate",
    order: "Order",
    attention: "Attention!",
    protectedPersonsAttention1:
        "1. There is no possibility to delete the persons involved in the case after they have been added!",
    protectedPersonsAttention2:
        "2. If you want to receive information on criminal or court cases against the added persons, information within the framework of Electronic Government, you must enter JSHSIR",
    selectfile: "Select from Files",
    drophere: "Or drop here",
    sendorders: "Sent orders",
    createOrder: "Create a new order",
    hour: "hour",
    onlinerequest: "ENROLL FOR ADMISSION ONLINE",
    share: "Share",
    contragentWorkers: "Employees of the organization",
    sureDeleteLawyer: "Are you sure you want to fire this employee?",
    sureSubmitLawyer: "Would you really hire this employee?",
    successMuve: "The employee was successfully released",
    toWhom: "to whom it is given",
    contractDate: "Date of contract",
    successSubmit: "The employee was successfully hired",
    forum: "Forum",
    evoluation: "Evoluation",
    providingLegal: "Legal aid",
    submitAplication: "Submit an application",
    businessLanguage: "Business language",

    fonLawyers: "Decisions concerning lawyers",
    Name: "Name",
    numberDoc: "Document number",
    type: "Type",
    mainName: "Ministry of Justice of the ",
    mainNameStrong: "Republic of Uzbekistan",
    contragentFullName: "The full name of Advocacy Structure",
    contragentName: "Name of Advocacy Structure",
    projectName: "Automated selection system of lawyers",
    lawyerCertificate: "Certificate of Advocate",
    username: "User name",
    firstName: "Name",
    lastName: "Surname",
    parentName: "Father's name",
    phone: "Phone number",
    email: "E-mail address",
    password: "Password",
    signUp: "Sign Up",
    lawyerrester: "Register of lawyers",
    requesite: "Requesite",
    date: "The date",
    wokEnd: "Validity period",
    startWork: "Date of employment",
    endWork: "Date of dismissal",
    countWorkers: "Number of employees",
    workPlace: "Workplace",
    perform: {
        null: "On the way",
        SuccessDelivered: "Success delivered",
        ReceiverDead: "Receiver dead",
        ReceiverNotLivesThere: "Receiver not lives there",
        IncompleteAddress: "Incomplete address",
        ReceiverRefuse: "Receiver refuse",
        NotAtHome: "Not at home",
        DidntAppearOnNotice: "Did not appear on notice",
        Defect: "Defect",
        TryPerform: "Try perform",
        OrganizationWithGivenAddressNotFound:
            "Organization with given address not found",
    },
    position: "Position",
    andOther: "Other",
    comment: "Comment",
    WARNING: "The certificate is about to expire",
    DANGER: "The certificate is about to expire",
    EXPIRED: "The certificate has expired",
    allRestore: "Upload all data",
    courseTotal: "Start date / end date",
    coursSize: "Course duration",
    signed: "Signed",
    nextTrain: "Next training",
    didntHaveCertificate: "Those who have not received a certificate",
    view: "View",
    areaOfOperation: "Area of operation",
    formingAnAplication: "Forming an application",
    contractPeriod: "Contract period",
    month: "month",
    administartion: "Administration",
    requests: "Requests",
    requestType: "Request type",
    contracts: "Contracts",
    signContract: "Sign a contract",
    cancelingContract: "Cancel a contract",
    viewContract: "View a contract",
    laawyerAttorneyNumber: "Lawyer's attorney accaunt number",
    MFO: "MFO",
    successSign: "The contract was successfully signed",
    contractWasSigned: "The contract was signed",
    sentForSignature: "Sent for signature",
    sentToDdeportment: "Sent to the department",
    rquestAccepted: "Request accepted",
    //  actions
    errorReq: "This field can not be empty!",
    errorEmail: "Email address entered wrong",
    errorField: "This entry is incorrect",
    search: "Search",
    deleteIt: "Do you really want to delete?",
    successSave: "Successfully saved",
    actions: "Actions",
    notFound: "Nothing found",
    save: "Save",
    code: "Code",
    home: "Home page",
    add: "Add",
    addEmployee: "Add Employee",
    edit: "Edit",
    pdf: "Download PDF",
    prev: "Back",
    cancel: "Cancel",
    delete: "Delete",
    choose: "Select",
    choosen: "Selected",
    noChoosen: "Not selected",
    clikForSelect: "Press to select",
    selectAll: "Select all",
    selectContragent: "Select contragent",
    address: {
        physical: "Physical location",
        location: "Geolocation",
        legal: "Legal address",
        phone: "Business phone",
        mobilePhone: "Personal phone number",
        email: "Email address",
        physicalRegionId: "Location address",
        physicalAddress: "Address",
        legalRegionId: "Legal address",
        legalAddress: "Address",
        place: "Street and home",
        live: "Residence address",
        fax: "Fax number (if any)",
    },
    confirmCreateContragent: "Do you want to create this advocacy structure",
    confirmUpdateCurator:
        "Do you want to replace the head of this advocacy structure?",
    print: "Print",
    // form fields
    title: "Serial",
    depType: "Organization type",
    regionType: "Order of region",
    orderNum: "Order number",
    showMap: "Show on map",
    prefix: "Prefix",
    innLawyer: "Identification number(P.I.N.I)",
    innContragent: "Identification number(P.I.N.I)",
    dirinn: "Director's STIR",
    dirfirstName: "Director's name",
    dirlastName: "Director's lastname",
    dirparentName: "Director's parentname",
    pochtaIndex: "Zip code",
    passport: "Passport",
    passportNumber: "Passport number",
    passportGivenDate: "Passport given date",
    passportGivenWho: "Passport issued",
    passportEndDate: "Validity of the passport",
    birthday: "Date of birth",
    birthyear: "Year of birth",
    onlyYear: "Only year",
    year: "Year",
    deadyear: "Year of death",
    mobilePhone: "Personal number",
    birthCountry: "State of birth",
    birthRegion: "Birthplace region",
    birthDistrict: "Hometown",
    fullName: "Full name",
    birthPlace: "Place of birth",
    passportDetails: "Passport details",
    additional: "Additional information",
    yearFormat: "yyyy",
    dateFormat: "dd-mm-yyyy",
    dateFormat2: "yyyy-mm-dd",
    photo: "Photo",
    photoType: "Photo format",
    beginWorkDate: "Date of issuance of a lawyer's certificate",
    endWorkDatel: "Срок действия лицензии адвоката",
    endWorkDate: "Date of dismissal",
    givenDate: "Date of issue",
    endYear: "Year of graduation",
    whereWork: "Place of work",
    givenYear: "Year of issue",
    dead: "Dead",
    noInformation: "No information",
    reason: "Reason",
    workType: "Where, who works as",
    whoGiven: "Given by whom",
    militaryRankNumber: "Military rank number",
    yearOfMilitaryService: "Year of service last year",
    division: "Section",
    groups: "Groups",
    class: "Class",
    subClass: "Subclass",
    ifut: "IFUT",
    okonx: "XXTUT",
    pnfl: "PINFL",
    pnflOrStir: "PINFL / STIR",
    shortName: "Short name (if any)",
    byPassportNumber: "Passport serial",
    directorPassportSerial: "Director's passport serial",
    directorPassportNumber: "Director's passport number",
    organizedDate: "Created day",
    orderFamilyType: "Sequence number",
    certificates: "Certificates",
    certificateNumber: "Lawyer Certificate Number",
    certificateGivenDate: "Date of issuance of a lawyer's certificate",
    qr_code: "QR-Code",
    check: "Check",
    director: "Director",
    contragentExistError: "This advocacy structure already exists",
    contragentExistErrorS: "This advocacy structure is not registered",
    checkLawyerFromBase: "Check the advocacy structure from the system",
    saveContragent: "Adding an advocacy structure to the system",
    saveLawyer: "Login to the lawyer",
    setCurator: "Director",
    chooseLicenseFile: "Add a lawyer's license",
    addFile: "Add file",
    fileError: "Add file",
    licenses: "Licenses",
    journal: "Journal",
    noteNumber: "Registration number",
    licenseTitle: `O'zbekiston respublikasi`,
    licenseTitle1: `adliya vazirligi`,
    "Advokat qo'shish": "Add a lawyer",
    "Birlashma qo'shish": "Add an Association",
    "Advokat birlashmasi tahrirlash": "Lawyer Association Edit",
    legislation: "Legislation",
    news: "News",
    videos: "Videos",
    tutorials: "Guides",
    lawyerOf: "Advokati",
    License: "Litsenziya",
    speciality: "Mutahasisligi",
    licenseGivenWho: "Licensed organization",
    contragentInfo: "Selected advocacy structure",
    allLawyers: "All lawyers",
    lawyers: "Lawyers",
    allMaleLawyers: "Number of male",
    allFemaleLawyers: "Number of female",
    addPassport: "Upload passport",
    getPassport: "View passport",
    getPassportError: "Passport not found",
    lawyerStatus: "Status of lawyers",
    minjustorgans: "Territorial bodies of justice",
    editLicenseStatus: "Edit attorney status",
    ACTIVE: "License is active",
    INACTIVE: "License suspended",
    PermanentlyInActive: "License status is permanently suspended",
    TemporarilyInActive: "License status is temporarily suspended",
    null: "Not Found",
    archiveList: "All documents related to the license",
    allList: "History of all licenses",
    courseName: "Course Name",
    trainCertificate: "Qualification certificate",
    permissions: {
        userManagement: "Management users",
        permissions: "Permissions",
        roles: "Roles",
    },
    // end actions
    // page titles
    // References
    references: "Information",
    //
    academicDegree: "Academic degree",
    academicDegreeAdd: "Add academic degree",
    academicDegreeUpdate: "Edit academic degree",
    //
    academicTitle: "Academic title",
    academicTitleAdd: "Add academic title",
    academicTitleUpdate: "Edit academic degree",
    //
    award: "Award",
    awardAdd: "Add award",
    awardUpdate: "Edit award",
    //
    color: "Color",
    colorAdd: "Add color",
    colorUpdate: "Edit color",
    //
    CandidateDoc: "Applicant Document",
    CandidateDocAdd: "Add Applicant Document",
    CandidateDocUpdate: "Change applicant's document",
    //
    educationalInstitution: "Educational institution",
    educationalInstitutionAdd: "Add educational institution",
    educationalInstitutionUpdate: "Edit educational institution",
    //
    education: "Qualification level",
    educationAdd: "Add qualification level",
    educationUpdate: "Edit qualification level",
    //
    educationType: "Type of educational institution",
    educationTypeAdd: "Add type of educational institution",
    educationTypeUpdate: "Edit type of educational institution",
    //
    familyMember: "Family membership",
    familyMemberAdd: "Add family members",
    familyMemberUpdate: "Edit family members",
    //
    gender: "Gender",
    genderAdd: "Add gender",
    genderUpdate: "Edit gender",
    //
    language: "Language",
    languageAdd: "Add language",
    languageUpdate: "Edit language",
    //
    languageStatus: "Language status",
    languageStatusAdd: "Add language status",
    languageStatusUpdate: "Edit language status",
    //
    militaryRank: "Military rank",
    militaryRankAdd: "Add military rank",
    militaryRankUpdate: "Edit military rank",
    //
    partyMembership: "Party membership",
    partyMembershipAdd: "Add party membership",
    partyMembershipUpdate: "Edit party membership",
    //
    passportSerial: "Passport Series",
    passportSerialAdd: "Add passport series",
    passportSerialUpdate: "Edit passport series",
    //
    rectal: "Military Commission",
    rectalAdd: "Add military commission",
    rectalUpdate: "Editing of the Military Commission",
    //
    specDiploma: "Specificity",
    specDiplomaAdd: "Add specificity",
    specDiplomaUpdate: " Edit specificity",
    //
    typePosition: "Job category",
    typePositionAdd: "Add job category",
    typePositionUpdate: "Edit job category",
    //
    region: "Region",
    departments: "Advocacy structures",
    soato: "SOATO",
    pc: "PC",
    SP_ID: "SP",
    //
    department: "Department",
    departmentAdd: "Add department",
    departmentUpdate: "Edit department",
    //
    departmentType: "Department type",
    departmentTypeAdd: "Add department type",
    departmentTypeUpdate: "Edit department type",
    //
    departmentLocationType: "Type of organization location",
    departmentLocationTypeAdd: "Add type of organizations location",
    departmentLocationTypeUpdate: "Edit organization location type",
    //
    workMode: "Type of work",
    workModeAdd: "Add work type",
    workModeUpdate: "Edit work type",
    yuridik: "Legal",
    //
    employee: "User",
    employeeAdd: "Add user",
    employeeUpdate: "Edit user",
    //
    contragent: "Association of Lawyers",
    contragentAdd: "Edit association of Lawyers",
    contragentUpdate: "Edit association of Lawyers",
    //
    maritalStatus: "Family status",
    maritalStatusAdd: "Add family status",
    maritalStatusUpdate: "Edit family status",
    //
    passportType: "Type of passport",
    passportTypeAdd: "Add type of passport",
    passportTypeUpdate: "Edit type of passport",
    //
    licenseType: "License type",
    licenseTypeAdd: "Add license type",
    licenseTypeUpdate: "Update license type",
    //
    licenseSerial: "License series",
    licenseSerialAdd: "Add license series",
    licenseSerialUpdate: "Update license series",
    //
    licenseNumber: "License number",
    licenseGivenDate: "License given date",
    licenseEndDate: "License end date",
    licenseSerialAndNumber: "Series and license number",
    //
    ownershipType: "Type of ownership",
    ownershipTypeAdd: "Add type of ownership",
    ownershipTypeUpdate: "Edit type of ownership",
    //
    lawyerAdd: "Add lawyer",
    lawyerUpdate: "Edit lawyer",
    //
    typesOfEconomicActivity: "Types of economic activity",
    typesOfEconomicActivityAdd: "Add type of economic activity",
    typesOfEconomicActivityUpdate: "Edit type of economic activity",
    //
    lawOffices: "Type of advocacy structure",
    lawOfficesAdd: "Add a type of advocacy structure",
    lawOfficesUpDate: "Editing the type of advocacy structure",
    //
    lawyerProfile: "Profile of lawyers",
    //
    nationality: "Nationality",
    nationalityAdd: "Add nation",
    nationalityUpdate: "Edit nation",
    //
    licenseStatus: "Legal status",
    licenseStatusAdd: "Add legal status",
    licenseStatusUpdate: "Edit attorney status",

    /////\\\\\    PROFILE      \\\\\\\\/////////
    /////\\\\\\                 \\\\\\\////////

    lawyerOne: "Lawyer",
    profileInfo: "Profile information",
    profileInfoUpdate: "Edit Profile Information",
    activity: "Working activity",
    profileAwards: "State awards",
    deputy: "Deputy",
    lawyerLicence: "Attorney's license",
    //
    contragentOne: "Board of Advocates",
    users: "Users",
    control: "Control",
    roles: "Roles",
    role: "Role",
    viewRoles: "View roles",
    viewdate: "view information",
    confirmation: "Confirmation",
    depDetails: "Organizational information",
    depName: "Organization name",
    depNotSelected: "Organization not selected",
    map: "Location",
    reference: "Reference",
    other: "Other",
    selectOne: "Select one",
    selectOneOrMore: "Select one or more",
    enterToRemove: "Click to close",
    enterToSelect: "Click to select",
    cv: "Lens",
    findInfo: "Bring the data",
    chooseImage: "Choose an image",
    deleteImage: "Delete image",
    statusSituation: "Status",
    // Login info
    loginUp: "Ministry of Lawyers of the Republic of Uzbekistan",
    loginDown: "Automated selection system of lawyers",
    Username: "Username",
    userNameRequired: "Username required",
    passwordRequired: "Password required",
    signIn: "Sign In",
    signOut: "Sign out",
    userProfile: "Profile",
    dashboardHeader: "Statistics on interregional legal entities",
    dashboardHeader1: "Statistics on legal entities",
    allCount: "Total count",
    lawyerF: "Firm of lawyers",
    lawyerB: "Bureau of lawyers",
    lawyerH: "Panel of lawyers",
    totalInfo: "All information",
    Qolganlari: "Others",
    /* addition */

    curentStateApplication: "The current status of your application",
    archiveApplication: "Application archive",
    sendedDate: "Date sent",
    send: "Send",
    passportOrOneIdData:
        "A copy of the passport of a citizen of the Republic of Uzbekistan or information on an identification ID card",
    educationData:
        "A copy of a diploma of higher education or a certificate of certification (recording of equivalence) of a document on higher education obtained in a foreign educational institution by an authorized body",
    workBookData:
        "A certified copy of the employment record or an extract from the 'Electronic employment record' system confirming the length of work in a legal specialty",
    internshipData:
        "A description issued by the law firm where the applicant interned",
    $3x4Data:
        "2 color photographs of the applicant measuring 3 x 4 cm (taken within the last 6 months)",
    abilityDealingData: "Information about the applicant's legal capacity",
    isConvictedData:
        "Information about whether or not the applicant has been convicted",

    //REGIONS
    Uzbekistan: "Republic of Uzbekistan",
    Surxandaryo: "Surkhandarya region",
    Toshkentshahar: "Tashkent city",
    Jizzah: "Jizzakh region",
    Qashqadaryo: "Kashkadarya region",
    Sirdaryo: "Syrdarya region",
    Toshkentviloyati: "Tashkent region",
    Namangan: "Namangan region",
    Fargona: "Fargana region",
    QoraqalpogistonRespublikasi: "Republic of Karakalpakstan",
    Navoi: "Navoi region",
    Buxoro: "Bukhara region",
    Xorazm: "Khorezm region",
    Samarqand: "Samarkand region",
    Andijon: "Andijan region",
    archive: "Archive",
    totalContragent: "Total count of advocate structure",
    cancelStaff: "Dismissal",

    /* home banner */
    homeBannerText: "Our site offers you",
    serviceLawyer: "Legal aid information system",
    register: "SIGN-UP",
    sign_in: "SIGN-IN",

    /* addition */
    aboutUs: "About us",
    recentNews: "Recent news",
    moreNews: "More news",
    readMore: "Read more",
    moreLawyers: "More lawyers",
    detailed: "Detailed",
    categories: "Categories",
    statistics: "Statistics",
    branches: "Branches",
    branchesLists: "All divisions",
    usefulLinks: "Useful links",
    sendDoc:
        "If you are applying for a lawyer, you can send documents through this form",
    telegramBotClick: `Contact us about technical problems on the e-advokat site through this Telegram bot`,
    presidentSite:
        "Official website of the President of the Republic of Uzbekistan",
    uniqueCountryService: "The only interactive public services portal",
    nationalTasks:
        "National goals and objectives in the field of sustainable development",
    lexUz: "National Database of Legislation of the Republic of Uzbekistan",
    adliya: "Official website of the Ministry of Justice of the Republic of Uzbekistan",
    yurxizmat:"Legal self-service system “Legal Tech”",
    oliyMajlis:
        "Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan",
    openDataPortal: "Open information portal of the Republic of Uzbekistan",

    /* footer */

    adres: "Republic of Uzbekistan 100047, Tashkent, Sailgokh street, 5",
    aboutMinistry: "About the ministry",
    ourCareer: "Our activities",
    openJustice: "Open justice",
    vacancy: "Vacancy",
    serviceInformation: "Information Service",
    e_library: "E-library",
    interactive: "Interactive Services",
    contact: "Communication",
    corruption: "Corruption",
    genderEquality: "Gender equality",
    appeal: "Appeal",
    bankName: "Bank name",
    citizensInTheSocialSecurityRegister:
        "Obtaining legal assistance at the expense of the state",
    citizensInTheSocialSecurityRegisterCreate:
        "Sending an application for legal assistance at the expense of the state",
    passportSeriesAndNumber: "Passport series and number",
    forPersonsWithDisabilities: "For persons with disabilities",
    eSign: "E-sign",
    pinfl: "PINI",
    applications: "Requests",
    subDepartment: "Sub-organization",
    caseNumber: "Case number",
    instance: "Instance",
    met: "Met",
    note: "Note !!!",
    applicationsCreateInfo:
        'If you did not stand the account as a low-income person in the information system” unified register of Social Protection", your application for legal assistance at the expense of the state will be automatically rejected.',
    courtName: "Court name",
    theTimeWhenTheCourtIsAdjourned: "The time when the court is adjourned",
    internalNumberOfTheWorkDocument: "Internal number of the work ",
    written: "Written",
    noMoreThan2500Words: "No more than 2500 words!",
    description: "Izoh",
    directoryRequestType: "Ariza turi",
    diplom: {
        title: "Diploma information",
    },
    new: "New",
    work: {
        title: "Labor activity",
        start_date: "Иш бошланган санаси",
        end_date: "Иш тугатган санаси",
        company_name: "Ташкилот",
        company_inn: "СТИР",
        position_name: "Лавозим",
        structure_name: "Бўлим",
    },
    conviction: {
        title: "Conviction or non-conviction status",
    },
    expertise: {
        type: "Expertise type",
        organization: "Organization",
        researchType: "researchType",
        comment: "Comment",
        paymentDetail:"Payment detail",
        paymentStatus:"Payment status",
        response:"Response",
        paymentAt:"PaymentAt",
        deadline:"Deadline",
        docType:"DocType",
        conclusionNumber:"Conclusion number",
        status: {
            DIRECTED: "Направленный"
        }

    }
};
