<template>
  <main class="flex flex-col min-h-screen">
    <navbar @sign="signatureModal = true" />
    <div class="flex-grow">
      <router-view @sign="signatureModal = true"></router-view>

      <n-drawer
        v-model:show="signatureModal"
        style="width: 600px"
        preset="card"
        :title="$t('electronSigning')"
        :bordered="false"
        size="huge"
      >
        <c-overlay :loading="loading">
          <Signature
            @success="signatureLogin"
            :selectedDoc="{}"
            :withoutMobileId="true"
          />
        </c-overlay>
      </n-drawer>
    </div>
    <footer-view />
  </main>
</template>

<script>
import Navbar from "./sections/NavbarView.vue";
import FooterView from "./sections/FooterView.vue";
import COverlay from "@/components/COverlay.vue";
import Signature from "@/components/signature.vue";
import ApiService from "@/services/api.service";
import { TokenService } from "@/services/storage.service";
export default {
  components: {
    Signature,
    COverlay,
    Navbar,
    FooterView,
  },
  data() {
    return {
      signatureModal: false,
      loading: false,
    };
  },
  methods: {
    async signatureLogin(imzo) {
      this.loading = true;
      let data = { signedData: imzo.signedContent, pnfl: imzo.pnfl };
      await ApiService.post(`/authPayloadByEsp`, data)
        .then((res) => {
          console.log("res.data", res.data);
          this.signatureModal = false;
          TokenService.saveToken(res?.data?.token);
          TokenService.setStatus(res?.data?.status);
          TokenService.setUploadPath(
            res?.data?.newUploadPath || res?.data?.uploadPath || ""
          );
          ApiService.setHeader();
          if (res.data.status == null && !res.data.isDirector) {
            TokenService.saveUserRoles("USER");
            this.$router.push({ path: "/lawyer-profile/user-info" });
            return;
          } else if (res.data.isDirector == true && res.data.status == null) {
            TokenService.saveUserRoles("NEW_DIRECTOR");
            this.$router.push({ path: "/lawyer-profile/user-info" });
            return;
          } else if (res.data.isDirector == true && res.data.status) {
            TokenService.saveUserRoles("DIRECTOR");
            TokenService.saveContragent(res.data.contragentId);
            this.$router.push({ path: "/lawyer-profile" });
            return;
          } else TokenService.saveUserRoles(res.data.status);
          this.$router.push({ path: "/lawyer-profile" });
        })
        .catch((e) => {
          TokenService.removeToken();
          this.$showError(
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : e.message
          );
        })
        .finally((f) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
