import ApiService from "../api.service";
const main = "getInfo";

export default {
    listLawyerForSend({ search, contragentId, params }) {
        return ApiService.post(
            `${main}/listLawyerForSend?search=${search}&contragentId=${
                contragentId || ""
            }`,
            params
        );
    },
    getList({ search, contragentId, regionId, params }) {
        return ApiService.post(
            `${main}/lawyerList?search=${search || ""}&contragentId=${
                contragentId || ""
            }&regionId=${regionId || ""}`,
            params
        );
    },
    getReceipt(id) {
        return ApiService.get(`getInfo/get-receipt/${id}`);
    },
    RegionList() {
        return ApiService.post(`${main}/directoryRegionList`);
    },
    contragentList({ search, typeId, regionId, isArchive, params }) {
        return ApiService.post(
            `${main}/contragentList?search=${search || ""}&isArchive=${
                isArchive || false
            }&typeId=${typeId || ""}&regionId=${regionId || ""}`,
            params
        );
    },
    contragentTypes() {
        return ApiService.post(`${main}/contragentTypeList?search=`, {
            limit: 50,
            page: 0,
        });
    },
    countInfo() {
        return ApiService.post(`${main}/count-info`);
    },
    directoryLicenseType() {
        return ApiService.post(`${main}/listAllLicenseType`, {
            limit: 50,
            page: 0,
        });
    },
    listOwnLicenseType() {
        return ApiService.get(`${main}/listOwnLicenseType`);
    },
    directoryDocType() {
        return ApiService.post(`directoryDocType/list`, { limit: 50, page: 0 });
    },
    legislationList(data) {
        return ApiService.post(`${main}/legislationList`, data);
    },
    lawyerBallCreate(data) {
        return ApiService.post(`${main}/lawyerBallCreate`, data);
    },
    // http://localhost:8282/api/v.1/getInfo/updateNew?email=wesfwegwe&phone=wefwegwegw
    updateNew({ email, phone, picture, ownAddress, resultList }) {
        let file = new FormData();
        file.append("file", picture);
        file.append("resultList", resultList);
        return ApiService.post(
            `${main}/updateNew?email=${email}&phone=${phone}&ownAddress=${ownAddress}`,
            file
        );
    },
    // http://localhost:8282/api/v.1/getInfo/checkLawyerStatus?pnfl=561651631
    checkLawyerStatus(pnfl) {
        return ApiService.post(
            `${main}/checkForCertificateRequest?pnfl=${pnfl}`
        );
    },
    getLawyerInfoDto(id) {
        return ApiService.get(`${main}/getLawyerInfoDto/${id}`);
    },
    lawyerOwnContragentUpdateGet(data) {
        return ApiService.get(`${main}/updateOwnContragent`, data);
    },
    lawyerOwnContragentUpdatePost(data) {
        return ApiService.post(`${main}/updateOwnContragent`, data);
    },
    getChamberDocsList(){
        return ApiService.post(`${main}/listDirectoryChamberDocs`,{page:0,limit:10})
    },
    getGuides(){
        return ApiService.post(`${main}/listNews`,{page:0,limit:10})
    },
    uploadFile(data) {
        return ApiService.post(`/file/upload`, data)
    },
    createLegalService(data){
        return ApiService.post('/clients/create-request',data)
    },
    cancelContractLegalAid(data){
        return ApiService.post('/getInfo/cancelContractLegalAid',data)
    }
};
